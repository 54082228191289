import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  SubTitle,
  Span,
  Textarea,
  Button,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Url Encoder / Decoder",
    link: "/url-encoder-and-decoder/",
  },
]
const seeAlsoArray = [
  "/base32-decoder",
  "/base32-encoder",
  "/base64-decoder",
  "/base64-encoder",
  "/binary-decoder",
  "/binary-encoder",
  "/morse-code-conversion",
]

function UrlEncoderDecoderPage() {
  let [dencoder, setdencoder] = useState("")
  function dencoderC(e) {
    setdencoder(e.target.value)
  }

  function encode() {
    setdencoder(
      encodeURIComponent(dencoder).replace(/'/g, "%27").replace(/"/g, "%22")
    )
  }
  function decode() {
    setdencoder(decodeURIComponent(dencoder.replace(/\+/g, " ")))
  }

  return (
    <>
      <SubTitle>Url Encoder/Decoder</SubTitle>

      <Span>Input Here :</Span>
      <Textarea
        className="mb-2"
        value={dencoder}
        onChange={dencoderC}
      ></Textarea>
      <Button
        borderColor="#1da1f2"
        color="#1da1f2"
        hoverColor="white"
        hoverBorderColor="#1da1f2"
        hoverBackgroundColor="#1da1f2"
        onClick={decode}
        value="Decode"
      >
        Decode
      </Button>
      <Button
        borderColor="#1da1f2"
        color="white"
        backgroundColor="#1da1f2"
        hoverColor="#1da1f2"
        hoverBorderColor="#1da1f2"
        onClick={encode}
        value="Encode"
      >
        Encode
      </Button>
    </>
  )
}

function UrlEncoderDecoder(props) {
  return (
    <Layout location={props.location}>
      <SEO
        title="URL Encoder Decoder Tools - Simple Encoder/Decoder URL"
        description="URL Encoder-Decoder Tools, Simple Encoder/Decoder URL, easy To Encode Decode Url, step one input Url and click Encode or input encode Url and click Decode to get Url Encode or Decode, step by step."
        keywords={[
          "Url Encode/decode, url encoder, url decoder, encode url, decode url, url encoder decoder, Encoder/Decoder Url,Encoder/Decoder.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Url Encoder and Decoder</Title>

        <UrlEncoderDecoderPage />

        <br />
        <FlexDiv maxWidth="300px" margin="auto">
          <SmallImg
            filename="url.png"
            alt="url to encode decode, convert url"
          />
        </FlexDiv>
        <br />
        <h3>URL Encoder/Decoder</h3>
        <p>
          URL encoding specific for encoding some characters in a URL by
          replacing them with one or more characters, that consist of the
          percent character (%) followed by two hexadecimal digits.
          <br />
          <strong>Reserved characters:</strong>
          <br />
          ([ ] ! * ' ( ) ; : @ & = + $ , / ? % #) <br />
          <strong>Unreserved characters:</strong>
          <br />
          (A to Z), (a to z), and (0 1 2 3 4 5 6 7 8 9 - _ . ~)
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default UrlEncoderDecoder
